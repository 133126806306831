<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>网站管理</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-card>
      <el-row>
        <el-button type="primary"
                   class="addbtn"
                   @click="showAddDialog">添加图片</el-button>
      </el-row>
      <!-- 列表 -->
      <el-table :data="showList"
                stripe
                style="width: 100%">
        <el-table-column type="index"
                         label="#"
                         width="80"></el-table-column>
        <el-table-column label="缩略图">
          <template slot-scope="scope">
            <el-image style="width: 192px; height: 80px"
                      :src="globalUrlapi + '/' + scope.row.logo"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="手机端缩略图">
          <template slot-scope="scope">
            <el-image style="width: 192px; height: 80px"
                      :src="globalUrlapi + '/' + scope.row.logo_m"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="是否启用"
                         prop="status">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="stateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="sort"
                         label="排序"
                         width="250">
          <template slot-scope="scope">
            <el-input size="mini"
                      v-model="scope.row.sort"
                      @blur="sortChange(scope.row)"
                      style="width: 60px;"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       v-if="scope.row.status === 0"
                       @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加编辑表单 -->
    <el-dialog :title="titleMap[dialogTitle]"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="50%"
               @close="addDialogClose">
      <!-- 表单区域 -->
      <el-form :model="dateForm"
               :rules="dateFormRules"
               ref="dateFormRef"
               label-position="top">
        <el-form-item label="标题："
                      prop="title">
          <el-input v-model="dateForm.title"></el-input>
        </el-form-item>
        <el-form-item label="内容："
                      prop="content">
          <el-input type="textarea"
                    :rows="3"
                    resize="none"
                    v-model="dateForm.content"></el-input>
        </el-form-item>
        <el-form-item label="链接地址："
                      prop="url">
          <el-input v-model="dateForm.url"></el-input>
        </el-form-item>
        <el-form-item label="轮播图上传："
                      prop="logo">
          <el-upload :data="imgtype"
                     :action="uploadURL"
                     :headers=" headerObj"
                     :limit="1"
                     :on-preview="handlePreview"
                     :before-upload="beforeUpload"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     list-type="picture">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <el-dialog :visible.sync="imgDialogVisible"
                     width="50%"
                     append-to-body>
            <img width="100%"
                 :src="dialogImageUrl">
          </el-dialog>
        </el-form-item>
        <el-form-item label="手机端轮播图上传："
                      prop="logo_m">
          <el-upload :data="imgtype"
                     :action="uploadURL"
                     :headers=" headerObj"
                     :limit="1"
                     :on-preview="handlePreview"
                     :before-upload="beforeUpload"
                     :on-success="mobileHandleSuccess"
                     :file-list="fileListTwo"
                     list-type="picture">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <el-dialog :visible.sync="imgDialogVisible"
                     width="50%"
                     append-to-body>
            <img width="100%"
                 :src="dialogImageUrl">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="upDate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {

      showList: [],
      titleMap: {
        addData: '添加 Banner',
        updateData: '修改 Banner'
      },
      dialogTitle: '',
      dialogVisible: false,
      // 表单
      dateForm: {
        content: '',
        title: '',
        url: '',
        logo: '',
        logo_m: ''
      },
      // 表单验证规则
      dateFormRules: {
        name: [
          { max: 50, message: '长度小于 30 个字符', trigger: 'blur' }
        ],
        content: [
          { max: 255, message: '长度小于 255 个字符', trigger: 'blur' }
        ],
        title: [],
        logo: [
          { required: true, message: '请选择上传图片', trigger: 'blur' }
        ]
      },

      // 图片上传地址
      uploadURL: this.globalUrlapi + '/logo',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imgtype: {
        type: 'banner'
      },
      fileList: [],
      fileListTwo: [],
      imgDialogVisible: false,
      dialogImageUrl: ''
    }
  },
  methods: {
    // 获取数据
    async getList () {
      const { data: res } = await this.$http.get('banner')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.showList = res.data
    },

    // 改变状态
    async stateChange (userinfo) {
      const { data: res } = await this.$http.put(`banner/${userinfo.id}?status=${userinfo.status}`)
      if (res.code !== 200) {
        userinfo.status = !userinfo.status
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getList()
    },

    // 排序
    async sortChange (userinfo) {
      const { data: res } = await this.$http.put(`banner/${userinfo.id}?sort=${userinfo.sort}`)
      if (res.code !== 200) {
        userinfo.sort = this.userinfo.sort
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getList()
    },

    // 删除该ID信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }
      const { data: res } = await this.$http.delete('banner/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('删除失败，请重试...')
      }
      this.$message.success('删除成功！')
      this.getList()
    },

    // 添加按钮
    showAddDialog () {
      this.dialogVisible = true
      this.dialogTitle = 'addData'
    },

    // 展示修改对话框
    async showEditDialog (id) {
      const { data: res } = await this.$http.get('banner/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.dateForm = res.data
      this.fileList.push({ name: res.data.logo, url: this.globalUrlapi + res.data.logo })
      this.fileListTwo.push({ name: res.data.logo_m, url: this.globalUrlapi + res.data.logo_m })
      this.dialogTitle = 'updateData'
      this.dialogVisible = true
    },

    // 关闭 Dialog，清除历史数据
    addDialogClose () {
      this.$refs.dateFormRef.resetFields()
      this.fileList = []
      this.fileListTwo = []
    },

    // 查看图片
    handlePreview (fileList) {
      this.dialogImageUrl = fileList.url
      this.imgDialogVisible = true
    },

    // 图片上传前校验
    beforeUpload (file) {
      const isIMAGE = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 500 < 1
      if (!isIMAGE) {
        this.$message.error('上传文件只能是 jpg 或 png 图片格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 500kb!')
      }
      return isIMAGE && isLt1M
    },

    // 图片上传成功回调
    handleSuccess (response) {
      this.dateForm.logo = response.data
    },

    mobileHandleSuccess (response) {
      this.dateForm.logo_m = response.data
    },

    upDate () {
      // 添加请求
      if (this.dialogTitle === 'addData') {
        this.$refs.dateFormRef.validate(async valid => {
          if (!valid) return
          // 如果正确发起添加请求
          const { data: res } = await this.$http.post('banner', this.dateForm)
          if (res.code !== 200) {
            this.$message.error('网络延时，请重新刷新网页重试...')
          }
          this.$message.success('添加成功！')
          // 隐藏对话框
          this.dialogVisible = false
          this.getList()
        })
      } else {
        // 修改请求
        this.$refs.dateFormRef.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.put('banner/' + this.dateForm.id, {
            content: this.dateForm.content,
            title: this.dateForm.title,
            url: this.dateForm.url,
            logo: this.dateForm.logo,
            logo_m: this.dateForm.logo_m
          })
          if (res.code !== 200) {
            return this.$message.error('网络延时，请重新刷新网页重试...')
          }
          this.$message.success('修改成功！')
          // 隐藏对话框
          this.dialogVisible = false
          this.getList()
        })
      }
    }

  },
  created () {
    this.getList()
  }

}
</script>

<style>
</style>
