<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>网站管理</el-breadcrumb-item>
      <el-breadcrumb-item>业务联系</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-card>
      <el-row>
        <el-button type="primary"
                   class="addbtn"
                   @click="showAddDialog">添加联系</el-button>
      </el-row>

      <!-- 视图列表区 -->
      <el-table :data="showList"
                stripe
                style="width: 100%">
        <el-table-column type="index"
                         label="#"
                         width="100"></el-table-column>
        <el-table-column prop="name"
                         label="联系人"></el-table-column>
        <el-table-column prop="mobile"
                         label="联系手机"></el-table-column>
        <el-table-column prop="email"
                         label="联系邮箱"></el-table-column>
        <el-table-column prop="phone"
                         label="联系电话"></el-table-column>

        <el-table-column label="首页显示"
                         prop="status">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="stateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       v-if="scope.row.status === 0"
                       @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加编辑表单 -->
    <el-dialog :title="titleMap[dialogTitle]"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               width="50%"
               @close="addDialogClose">
      <!-- 表单区域 -->
      <el-form :model="dateForm"
               :rules="dateFormRules"
               ref="dateFormRef"
               label-position="top">
        <el-form-item label="联系人："
                      prop="name">
          <el-input v-model="dateForm.name"></el-input>
        </el-form-item>
        <el-form-item label="联系手机："
                      prop="mobile">
          <el-input v-model="dateForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱："
                      prop="email">
          <el-input v-model="dateForm.email"></el-input>
        </el-form-item>
        <el-form-item label="联系电话："
                      prop="phone">
          <el-input v-model="dateForm.phone"></el-input>
        </el-form-item>

        <el-form-item label="个人微信二维码上传："
                      prop="logo">
          <el-upload :data="imgtype"
                     :action="uploadURL"
                     :headers=" headerObj"
                     :limit="1"
                     :on-preview="handlePreview"
                     :before-upload="beforeUpload"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     list-type="picture">
            <el-button size="small"
                       type="primary">点击上传</el-button>
            <div slot="tip"
                 class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
          <el-dialog :visible.sync="imgDialogVisible"
                     width="50%"
                     append-to-body>
            <img width="100%"
                 :src="dialogImageUrl"
                 alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="upDate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {

      showList: [],

      titleMap: {
        addData: '添加联系人',
        updateData: '修改联系人信息'
      },
      dialogTitle: '',

      dialogVisible: false,

      cateList: [],

      // 表单
      dateForm: {
        name: '',
        mobile: '',
        email: '',
        phone: '',
        logo: ''
      },

      // 表单验证规则
      dateFormRules: {
        name: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          { max: 100, message: '长度小于 100 个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入联系人联系手机', trigger: 'blur' },
          { max: 255, message: '长度小于 255 个字符', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入联系人邮箱', trigger: 'change' }
        ]
      },
      // 图片上传地址
      uploadURL: this.globalUrlapi + '/logo',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imgtype: {
        type: 'type'
      },
      fileList: [],
      imgDialogVisible: false,
      dialogImageUrl: ''
    }
  },

  created () {
    this.getList()
  },

  methods: {
    // 获取全部数据列表
    async getList () {
      const { data: res } = await this.$http.get('types')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.showList = res.data
    },

    // 改变状态
    async stateChange (userinfo) {
      const { data: res } = await this.$http.put(`types/${userinfo.id}?status=${userinfo.status}`)
      if (res.code !== 200) {
        userinfo.status = !userinfo.status
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getList()
    },

    // 删除该ID信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除这个应用分类吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }
      const { data: res } = await this.$http.delete('types/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('删除失败，请重试...')
      }
      this.$message.success('删除成功！')
      this.getList()
    },

    // 添加按钮
    showAddDialog () {
      this.dialogVisible = true
      this.dialogTitle = 'addData'
      this.getCateList()
    },

    // 展示修改对话框
    async showEditDialog (id) {
      const { data: res } = await this.$http.get('types/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.dateForm = res.data
      this.fileList.push({ name: res.data.logo, url: this.globalUrlapi + res.data.logo })
      this.dialogTitle = 'updateData'
      this.dialogVisible = true
      this.getCateList()
    },

    // 获取分类列表信息
    async getCateList () {
      const { data: res } = await this.$http.get('types/create')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请重新刷新网页重试...')
      }
      this.cateList = res.data
    },

    // 关闭 Dialog，清除历史数据
    addDialogClose () {
      this.$refs.dateFormRef.resetFields()
      this.fileList = []
    },

    // 查看图片
    handlePreview (fileList) {
      this.dialogImageUrl = fileList.url
      this.imgDialogVisible = true
    },

    // 图片上传前校验
    beforeUpload (file) {
      const isIMAGE = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 500 < 1
      if (!isIMAGE) {
        this.$message.error('上传文件只能是 jpg 或 png 图片格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 500kb!')
      }
      return isIMAGE && isLt1M
    },

    // 图片上传成功回调
    handleSuccess (response) {
      this.dateForm.logo = response.data
    },

    upDate () {
      // 添加请求
      if (this.dialogTitle === 'addData') {
        this.$refs.dateFormRef.validate(async valid => {
          if (!valid) return
          // 如果正确发起添加请求
          const { data: res } = await this.$http.post('types', this.dateForm)
          if (res.code !== 200) {
            this.$message.error('网络延时，请重新刷新网页重试...')
          }
          this.$message.success('添加成功！')
          // 隐藏对话框
          this.dialogVisible = false
          this.getList()
        })
      } else {
        // 修改请求
        this.$refs.dateFormRef.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.put('types/' + this.dateForm.id, {
            name: this.dateForm.name,
            content: this.dateForm.content,
            cate_id: this.dateForm.cate_id,
            logo: this.dateForm.logo
          })
          if (res.code !== 200) {
            return this.$message.error('修改管理员信息失败，请重试。')
          }
          this.$message.success('修改成功！')
          // 隐藏对话框
          this.dialogVisible = false
          this.getList()
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
